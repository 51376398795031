<template>
  <base-section space="4">
    <base-section-title keyTranslate="prizesHistory" />

    <v-container class="center">
      <BackButton :route="getRouteBack" />
    </v-container>

    <PrizesHistoryContainer />
  </base-section>
</template>

<script>
import { prizesRoutesName } from "@/router/names";

export default {
  name: "PrizesHistorySection",
  provide: {
    heading: { align: "center" },
  },
  components: {
    BackButton: () => import("@/components/buttons/BackButton.vue"),
    PrizesHistoryContainer: () =>
      import("@/components/prizes/history/PrizesHistoryContainer.vue"),
  },
  computed: {
    getRouteBack() {
      return {
        name: prizesRoutesName.INDEX.name,
      };
    },
  },
};
</script>
